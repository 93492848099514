import { FC } from 'react';
import IconProps from './IconProps';

const DataMinimizationIcon: FC<IconProps> = (props) => {
  const { onClick, className, gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.1698 0.366699H2.29627C1.78413 0.366699 1.29296 0.570147 0.930821 0.932286C0.568682 1.29443 0.365234 1.78559 0.365234 2.29773V4.22877C0.365344 4.72039 0.552961 5.19346 0.889832 5.55153L5.51466 10.466V17.1023C5.51466 17.4609 5.61452 17.8125 5.80306 18.1175C5.99159 18.4226 6.26135 18.6691 6.58211 18.8295C6.90287 18.9899 7.26194 19.0578 7.61911 19.0256C7.97628 18.9934 8.31742 18.8623 8.60431 18.6472L11.179 16.7161C11.4189 16.5363 11.6135 16.303 11.7476 16.0349C11.8816 15.7667 11.9514 15.4711 11.9514 15.1713V10.466L16.5763 5.55153C16.9131 5.19346 17.1008 4.72039 17.1009 4.22877V2.29773C17.1009 1.78559 16.8974 1.29443 16.5353 0.932286C16.1731 0.570147 15.682 0.366699 15.1698 0.366699ZM15.1698 4.22877L10.0204 9.70003V15.1713L7.44569 17.1023V9.70003L2.29627 4.22877V2.29773H15.1698V4.22877Z"
          fill="url(#paint0_linear_11092_3039)"
        />
        <defs>
          <linearGradient id="paint0_linear_11092_3039" x1="0.365234" y1="6.06645" x2="17.624" y2="12.681" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0533 2.13333H4.34069C3.75538 2.13333 3.19405 2.36584 2.78017 2.77972C2.3663 3.19359 2.13379 3.75492 2.13379 4.34023V6.54712C2.13391 7.10898 2.34833 7.64963 2.73333 8.05885L8.01885 13.6754V21.2598C8.01885 21.6696 8.13298 22.0714 8.34844 22.42C8.56391 22.7686 8.87221 23.0504 9.23879 23.2337C9.60537 23.417 10.0157 23.4946 10.4239 23.4577C10.8321 23.4209 11.222 23.2712 11.5499 23.0253L14.4924 20.8184C14.7665 20.6128 14.989 20.3463 15.1422 20.0398C15.2954 19.7334 15.3752 19.3955 15.3752 19.0529V13.6754L20.6607 8.05885C21.0457 7.64963 21.2601 7.10898 21.2602 6.54712V4.34023C21.2602 3.75492 21.0277 3.19359 20.6138 2.77972C20.2 2.36584 19.6386 2.13333 19.0533 2.13333ZM19.0533 6.54712L13.1683 12.8V19.0529L10.2257 21.2598V12.8L4.34069 6.54712V4.34023H19.0533V6.54712Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataMinimizationIcon;
